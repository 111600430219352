import React, { Component } from "react";
import "./PreciosTableStyle.css"


  
class PreciosTable extends Component {

    
    render() {
        return (
            <section id="precios_secc" className="PreciosSecc">
                <div className="container">
                    <div className="row">
                        <div class="col-12"><h2 className="Precios__title">Costos</h2></div>
                    </div>
                    <div className="row">
                        <div class="col-1">&nbsp;</div>
                        <div className="col-xs-10">
                            <table class="table tabla_precios">
                                <thead>
                                    <tr>
                                        
                                        <th  colspan="1" scope="col" className="Precios_border_cell1">Tipo de Póliza</th>
                                    </tr>
                                    <tr>
                                        <th colspan="1" scope="col" className="Precios_border_cell1">Costo de la Renta</th>
                                        <th scope="col" className="Precios_border_cell3">Elemental</th>
                                        <th scope="col" className="Precios_border_cell3">Elemental Plus</th>
                                        <th scope="col" className="Precios_border_cell3">Protección Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="table-active">
                                        <td className="Precios_border_cell3">$ 2,000 a $ 5,000 </td>
                                        <td>$&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2,800.00 </td>
                                        <td>$&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3,360.00 </td>
                                        <td>$&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4,032.00 </td>
                                    </tr>
                                    <tr class="table-active">
                                        <td className="Precios_border_cell3">$ 5,001 a $ 10,000 </td>
                                        <td>$&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3,200.00 </td>
                                        <td>$&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3,840.00 </td>
                                        <td>$&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4,608.00 </td>
                                    </tr>
                                    <tr class="table-active">
                                        <td className="Precios_border_cell3">$ 10,001 a $ 14,999 </td>
                                        <td>$&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4,200.00 </td>
                                        <td>$&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5,040.00 </td>
                                        <td>$&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6,048.00 </td>
                                    </tr>
                                    <tr class="table-active">
                                        <td className="Precios_border_cell3">$ 15,000 en adelante </td>
                                        <td>30%* </td>
                                        <td>50%* </td>
                                        <td>100%* </td>
                                    </tr>
                                    
                                </tbody>
                            </table>
                        </div>
                        <div class="col-1">&nbsp;</div>
                        </div>

                        <div className="row">
                            <div class="col-12">
                                <p className="Precios_detalle"> * El porcentaje se basa en el valor de la renta del inmueble.</p>
                            </div>
                        </div>
                </div>
            </section>
            
            );
        }
}

export default PreciosTable;
